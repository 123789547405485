:root {
  /* Only use CSS vars if not using IE */
  --flash-anim-duration: 10s;
}

body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #121212;
  background-color: #F6F6F6;
  font-size: 1.2em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, select {
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  border: 1px solid #CECECE;
  color: #121212;
  font-size: 1em;
  border-radius: 4px;
  padding: 5px 15px !important;
}

input {
  box-sizing: border-box;
}

input[type='checkbox'] {
  width: 18px;
  height: 18px;
}

select {
  border-radius: 4px;
}

button {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.2em;
}

textarea {
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
  border: 1px solid #CECECE;
  color: #121212;
  font-size: 1em;
  border-radius: 4px;
  padding: 10px 15px !important;
  min-height: 70px;
  max-height: 300px;
}

input:focus, select:focus, textarea:focus {
  outline: none !important;
  border: 1px solid #1591BF;
  border-radius: 4px;
  box-shadow: 0 0 2px 2px rgb(93, 196, 255);
}

textarea:focus {
  border-radius: 4px;
}

select:focus {
  border-radius: 4px;
}

p {
  margin-top: 20px;
  margin-bottom: 10px;
}

p.error {
  font-size: 1em;
  margin: 0px;
  margin-top: 10px;
  color: #d9143a;
}

li {
  margin: 8px 0px;
  margin-left: 10px;
}

.Toastify__toast {
  padding: 10px 10px 10px 15px !important;
  border-radius: 4px !important;
}

.regular-checkbox {
	margin-right: 10px;
}


/* Map Marker Styling */
.marker-wrapper {
  position: relative;
}

.marker-img {
  position: absolute;
  width: 28px;
  height: 28px;
}

.leaflet-top .leaflet-control {
  /* margin-top: 65px; */
}

/* Bikeway Tooltip Styling */ 
.bikeway-tooltip {
  /* color:#59a1e4; */
  font-family: 'Source Sans Pro', sans-serif;
  padding: 10px;
  font-size: 1.2em;
  background-color: #e7f3ff;
  border-color: #e7f3ff;
  border-radius: 6px;
}

.leaflet-tooltip {
  border-radius: 6px;
}

.leaflet-tooltip-left.bikeway-tooltip::before {
  border-left-color: #e7f3ff;
}
.leaflet-tooltip-right.bikeway-tooltip::before {
  border-right-color: #e7f3ff;
}

.bikeway-tooltip p {
  margin: 0px;
}

.test {
  color: #6d2367;
  color: #0b352a;
}